import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSetActiveTab from "../../../../components/hooks/useSetActiveTab";
import TabHeader from "../../../../components/layouts/TabHeader";
import { TAB_NAMES } from "../../../../components/layouts/menuNavigation";
import DeleteModal from "../../../../components/modals/DeleteModal";
import { useNotification } from "../../../../components/notification/NotificationContext";
import { getUserRoleForActiveGym } from "../../../../redux/selectors/app";
import { useDeleteGymAttendance, useGetGymAttendanceList } from "../apis";

const AttendanceList = () => {
  useSetActiveTab(TAB_NAMES.ATTENDANCE);
  const navigate = useNavigate();
  const { successNotification, errorNotification } = useNotification();
  const [idToOperatem, setIdToOperate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");

  const userRole = useSelector(getUserRoleForActiveGym);
  const isAdmin = userRole === 2;

  const handleDeleteClick = useCallback((staffId) => {
    setIdToOperate(staffId);
    setIsDeleteModalOpen(true);
  }, []);

  const columns = useColumns(handleDeleteClick);
  const {
    isLoading,
    data,
    mutate: refreshExpenses,
    isValidating,
  } = useGetGymAttendanceList({ searchText, date });

  const {
    isLoading: isDeleteLoading,
    execute: deleteGymStaff,
    error: deleteGymStaffError,
    isSuccess: deleteGymStaffSuccess,
  } = useDeleteGymAttendance();

  useEffect(() => {
    if (deleteGymStaffSuccess) {
      refreshExpenses();
      successNotification();
      setIsDeleteModalOpen(false);
    }
  }, [deleteGymStaffSuccess, refreshExpenses, successNotification]);

  useEffect(() => {
    if (deleteGymStaffError) {
      errorNotification();
    }
  }, [deleteGymStaffError, errorNotification]);

  const adminTabHeaderProps = {
    onSearch: (text) => setSearchText(text),
    onButtonClick: () => navigate("/attendance/add"),
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onSubmit={() => deleteGymStaff({ attendanceId: idToOperatem })}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
      <div className="flex flex-col gap-5">
        <TabHeader
          label="Attendances"
          buttonLabel="Add Attendance"
          onReload={refreshExpenses}
          onDateChange={setDate}
          date={date}
          showCheckInBtn={!isAdmin}
          searchPlaceholder="Search by Name"
          {...(isAdmin && adminTabHeaderProps)}
        />
        <Table
          // columns={columns}
          columns={
            isAdmin
              ? columns
              : columns.filter(
                  (col) => !["id", "staff"].includes(col.dataIndex)
                )
          }
          dataSource={data?.data?.rows ?? []}
          loading={isLoading || isValidating}
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

export default AttendanceList;

const useColumns = (handleDeleteClick) => {
  const navigate = useNavigate();
  const userRole = useSelector(getUserRoleForActiveGym);
  const isAdmin = userRole === 2;
  return [
    {
      title: () => <div className="flex justify-center">Name</div>,
      dataIndex: "staff",
      key: "staff",
      render: (text) => (
        <div className="flex justify-center">{text?.name ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Date</div>,
      dataIndex: "date",
      key: "date",
      render: DateCell,
    },
    {
      title: () => <div className="flex justify-center">Start Time</div>,
      dataIndex: "startTime",
      key: "startTime",
      render: (text) => (
        <div className="flex justify-center">
          {dayjs(text).format("HH:mm") ?? "-"}
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">End Time</div>,
      dataIndex: "endTime",
      key: "endTime",
      render: (text) => (
        <div className="flex justify-center">
          {text ? dayjs(text).format("HH:mm") : "-"}
        </div>
      ),
    },

    {
      title: () => <div className="flex justify-center">Actions</div>,
      dataIndex: "id",
      key: "id",
      // fixed: "right",
      render: (_, record) => (
        <div className="flex justify-center gap-3">
          <Button
            type="primary"
            onClick={() => navigate(`/attendance/add/${record.id}`)}
            icon={<EditOutlined />}
          ></Button>
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteClick(record.id)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      ),
    },
  ];
};

export const DateCell = (date) => {
  return (
    <div className="flex justify-center">
      {date ? dayjs(date).format("DD MMM YYYY") : "-"}
    </div>
  );
};
