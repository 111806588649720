import { LoadingOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  Input as AntInput,
  Button,
  DatePicker,
  InputNumber,
  Select,
  Spin,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import useSetActiveTab from "../../../components/hooks/useSetActiveTab";
import Input, { ElementWithLabel } from "../../../components/inputs/Input";
import { TAB_NAMES } from "../../../components/layouts/menuNavigation";
import { useNotification } from "../../../components/notification/NotificationContext";
import { genderOptions } from "../../workouts/exercise-filters/constants";
import { useAddGymMember, useGetGymMember, useUpdateGymMember } from "../apis";
const { TextArea } = AntInput;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const FORM_FIELDS = {
  IMAGE: "image",
  NAME: "name",
  EMAIL: "email",
  PHONE: "mobile",
  EMERGENCY_PHONE: "emergencyPhone",
  ADDRESS: "address",
  PASSWORD: "password",
  ROLE: "role",
  GENDER: "gender",
  DOB: "dob",
  DOJ: "doj",
  EXPERIENCE: "experience",
  PLAN: "plan",
};

const AddMember = () => {
  useSetActiveTab(TAB_NAMES.MEMBERS);
  const navigate = useNavigate();
  const { memberId } = useParams();
  const { errorNotification, successNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  // const { data } = useGetGymPlans();
  // const plansData = useMemo(
  //   () =>
  //     (data?.data ?? [])?.map((item) => ({ label: item.name, value: item.id })),
  //   [data?.data]
  // );
  const {
    execute: addStaff,
    isSuccess,
    error: addErr,
    inProgress: isAddInProgress,
  } = useAddGymMember();
  const { data: memberResponse, isLoading: isGetMemberLoading } =
    useGetGymMember(Number(memberId));
  const {
    execute: updateMember,
    isSuccess: isUpdateSuuccess,
    error: updateErr,
    inProgress: isUpdateInProgress,
  } = useUpdateGymMember();
  const memberData = useMemo(() => {
    const userData = memberResponse?.data ?? {};
    const { emergencyPhone, dob, doj, address, experience } = userData ?? {};
    return {
      ...userData,
      // role: userData?.gymAccess?.[0]?.role,
      emergencyPhone,
      dob,
      doj,
      address,
      experience,
    };
  }, [memberResponse?.data]);

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.NAME]: memberData?.[FORM_FIELDS.NAME] ?? "",
        [FORM_FIELDS.EMAIL]: memberData?.[FORM_FIELDS.EMAIL] ?? "",

        [FORM_FIELDS.PHONE]: memberData?.[FORM_FIELDS.PHONE] ?? null,
        [FORM_FIELDS.IMAGE]: memberData?.[FORM_FIELDS.IMAGE] ?? "",
        // [FORM_FIELDS.ROLE]: memberData?.[FORM_FIELDS.ROLE] ?? "",
        [FORM_FIELDS.GENDER]: memberData?.[FORM_FIELDS.GENDER] ?? "",

        [FORM_FIELDS.EMERGENCY_PHONE]:
          memberData?.[FORM_FIELDS.EMERGENCY_PHONE] ?? "",
        [FORM_FIELDS.DOB]: memberData?.[FORM_FIELDS.DOB] ?? null,
        [FORM_FIELDS.DOJ]: memberData?.[FORM_FIELDS.DOJ] ?? null,
        [FORM_FIELDS.EXPERIENCE]: memberData?.[FORM_FIELDS.EXPERIENCE] ?? 0,
      },
      validationSchema: object({
        [FORM_FIELDS.EMAIL]: string()
          .email("Please enter valid email.")
          .required("This is a Required field."),
        [FORM_FIELDS.NAME]: string().required("This is a Required field."),
        // [FORM_FIELDS.PASSWORD]: string().required("This is a Required field."),
      }),
      onSubmit: (data) => {
        const newObj = {};

        Object.keys(data).forEach((prop) => {
          if (data[prop] !== "" && data[prop] !== null) {
            newObj[prop] = data[prop];
          }
        });

        if (memberId) {
          updateMember({ role: 5, ...newObj, staffId: Number(memberId) });
          return;
        }
        addStaff(newObj);
      },
    });

  const handleImageChange = (info) => {
    setFieldValue(FORM_FIELDS.IMAGE, info.file.originFileObj);
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    // if (info.file.status === "done") {
    //   // Get this url from response in real world.
    //   getBase64(info.file.originFileObj, (url) => {
    //     setLoading(false);
    //     setFieldValue(FORM_FIELDS.IMAGE, url);
    //   });
    // }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file?.type === "image/jpeg" || file?.type === "image/png";
    if (!isJpgOrPng) {
      errorNotification("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorNotification("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {
    if (isSuccess || isUpdateSuuccess) {
      successNotification();
      navigate("/members");
    }
  }, [isSuccess, isUpdateSuuccess, navigate, successNotification]);

  useEffect(() => {
    if (updateErr || addErr) {
      if (addErr?.cause?.status === 409) {
        errorNotification(
          "User Already exist. If you can't find it in here please check staff list."
        );
      } else {
        errorNotification();
      }
    }
  }, [addErr, errorNotification, updateErr]);

  const restFormDisabled = useMemo(() => {
    return false;
    return errors[FORM_FIELDS.EMAIL] || !values[FORM_FIELDS.EMAIL];
  }, [errors, values]);

  return (
    <div className="flex flex-col bg-white w-full rounded-xl">
      <div className="text-xl font-bold flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-[#F4F7FE] cursor-pointer"
            onClick={() => navigate("/members")}
          >
            <ChevronLeftIcon />
          </div>
          <span>Add Member</span>
        </div>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={isAddInProgress || isUpdateInProgress}
        >
          Submit
        </Button>
      </div>
      <HorizontalSeparator />
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 ">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Use a permanent address where you can receive mail.
          </p>
        </div>

        {isGetMemberLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        ) : (
          <form className="md:col-span-2">
            <div className="flex flex-col gap-3">
              <div className="col-span-full flex items-center gap-x-8">
                <Upload
                  name={[FORM_FIELDS.IMAGE]}
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  //action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  beforeUpload={beforeUpload}
                  onChange={handleImageChange}
                  customRequest={() => {}}
                  disabled={restFormDisabled}
                >
                  {values[FORM_FIELDS.IMAGE] ? (
                    <img
                      src={
                        typeof values[FORM_FIELDS.IMAGE] === "string"
                          ? `${process.env.REACT_APP_API_URL}/uploads/${
                              values[FORM_FIELDS.IMAGE]
                            }`
                          : URL.createObjectURL(values[FORM_FIELDS.IMAGE])
                      }
                      alt="avatar"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <div>
                  <button
                    type="button"
                    className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-white/20"
                  >
                    Change avatar
                  </button>
                  <p className="mt-2 text-xs leading-5 text-gray-400">
                    JPG, GIF or PNG. 1MB max.
                  </p>
                </div>
              </div>

              <Input
                id={FORM_FIELDS.NAME}
                label="Full Name"
                name={FORM_FIELDS.NAME}
                value={values[FORM_FIELDS.NAME]}
                error={
                  errors[FORM_FIELDS.NAME] &&
                  touched[FORM_FIELDS.NAME] &&
                  errors[FORM_FIELDS.NAME]
                }
                onChange={handleChange}
                disabled={restFormDisabled}
                required
              />
              <Input
                id={FORM_FIELDS.EMAIL}
                type="email"
                label="Email"
                name={FORM_FIELDS.EMAIL}
                value={values[FORM_FIELDS.EMAIL]}
                error={
                  errors[FORM_FIELDS.EMAIL] &&
                  touched[FORM_FIELDS.EMAIL] &&
                  errors[FORM_FIELDS.EMAIL]
                }
                onChange={handleChange}
                disabled={!!memberId}
                required
              />
              {/* <Input
              id={"password"}
              type="password"
              label="Enter Password"
              name={FORM_FIELDS.PASSWORD}
              value={values[FORM_FIELDS.PASSWORD]}
              error={errors[FORM_FIELDS.PASSWORD]}
              onChange={handleChange}
              disabled={false}
            /> */}
              <div className="flex gap-3">
                <ElementWithLabel
                  label="Gender"
                  error={
                    errors[FORM_FIELDS.GENDER] &&
                    touched[FORM_FIELDS.GENDER] &&
                    errors[FORM_FIELDS.GENDER]
                  }
                >
                  <Select
                    showSearch
                    placeholder="Select Gender"
                    optionFilterProp="children"
                    value={values[FORM_FIELDS.GENDER]}
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.GENDER, value)
                    }
                    onSearch={() => {}}
                    options={genderOptions}
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
              </div>
              <Input
                id={FORM_FIELDS.PHONE}
                type="number"
                label="Mobile No."
                name={FORM_FIELDS.PHONE}
                value={values[FORM_FIELDS.PHONE]}
                error={
                  errors[FORM_FIELDS.PHONE] &&
                  touched[FORM_FIELDS.PHONE] &&
                  errors[FORM_FIELDS.PHONE]
                }
                onChange={handleChange}
                disabled={restFormDisabled}
              />
              <Input
                id={FORM_FIELDS.EMERGENCY_PHONE}
                type="number"
                label="Emergency Mobile No."
                name={FORM_FIELDS.EMERGENCY_PHONE}
                value={values[FORM_FIELDS.EMERGENCY_PHONE]}
                error={
                  errors[FORM_FIELDS.EMERGENCY_PHONE] &&
                  touched[FORM_FIELDS.EMERGENCY_PHONE] &&
                  errors[FORM_FIELDS.EMERGENCY_PHONE]
                }
                onChange={handleChange}
                disabled={restFormDisabled}
              />
              {/* <ElementWithLabel label={"Address"} name={FORM_FIELDS.ADDRESS}>
              <TextArea
                id={FORM_FIELDS.ADDRESS}
                label="Address"
                name={FORM_FIELDS.ADDRESS}
                value={values[FORM_FIELDS.ADDRESS]}
                error={errors[FORM_FIELDS.ADDRESS]}
                onChange={handleChange}
                disabled={false}
              />
            </ElementWithLabel> */}
              <div className="flex gap-3 w-full">
                <ElementWithLabel
                  label={"Date of Birth"}
                  name={FORM_FIELDS.DOB}
                  error={
                    errors[FORM_FIELDS.DOB] &&
                    touched[FORM_FIELDS.DOB] &&
                    errors[FORM_FIELDS.DOB]
                  }
                >
                  <DatePicker
                    onChange={(value) => setFieldValue(FORM_FIELDS.DOB, value)}
                    name={FORM_FIELDS.DOB}
                    value={
                      values[FORM_FIELDS.DOB]
                        ? dayjs(values[FORM_FIELDS.DOB])
                        : null
                    }
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label={"Date of Joining"}
                  name={FORM_FIELDS.DOJ}
                  error={
                    errors[FORM_FIELDS.DOJ] &&
                    touched[FORM_FIELDS.DOJ] &&
                    errors[FORM_FIELDS.DOJ]
                  }
                >
                  <DatePicker
                    onChange={(value) => setFieldValue(FORM_FIELDS.DOJ, value)}
                    name={FORM_FIELDS.DOJ}
                    value={
                      values[FORM_FIELDS.DOJ]
                        ? dayjs(values[FORM_FIELDS.DOJ])
                        : null
                    }
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label={"Experience"}
                  name={FORM_FIELDS.EXPERIENCE}
                  error={
                    errors[FORM_FIELDS.EXPERIENCE] &&
                    touched[FORM_FIELDS.EXPERIENCE] &&
                    errors[FORM_FIELDS.EXPERIENCE]
                  }
                >
                  <InputNumber
                    min={1}
                    max={10}
                    defaultValue={0}
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.EXPERIENCE, value)
                    }
                    changeOnWheel
                    value={values[FORM_FIELDS.EXPERIENCE]}
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
              </div>
              {/* <ElementWithLabel label="Plan">
              <Select
                showSearch
                placeholder="Select Plan"
                optionFilterProp="children"
                value={values[FORM_FIELDS.PLAN]}
                onChange={(value) => setFieldValue(FORM_FIELDS.PLAN, value)}
                onSearch={() => {}}
                options={plansData}
                disabled={restFormDisabled}
              />
            </ElementWithLabel> */}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddMember;

export const HorizontalSeparator = () => {
  return (
    <div className="w-full bg-gradient-to-r from-[#F4F7FE] bg-white h-[1px] py-[1px]"></div>
  );
};
