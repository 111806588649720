import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../hooks/useFetchAsync";
import { get, post } from "../../http-clients/clients";
import { getActiveGymId } from "../../redux/selectors/app";
import { addQueryParametersToUrl } from "../../utils/helpers";

export const useSendNotifications = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addSets = useCallback(
    (data) => {
      return post(`/gym/${activeGymId}/send-notifications`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addSets);
};

export const useGetAllGymMembers = () => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(`/gym/${activeGymId}/all-gym-members`, get);
};

export const useGetSentNotifications = ({ searchText, date }) => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(
    `/gym/${activeGymId}/sent-notifications${addQueryParametersToUrl({
      search: searchText,
      date,
    })}`,
    get
  );
};
