import { getTokenFromLocalStorage, logout } from "../utils/helpers";

const call = async (url, method, data) => {
  let isMultiPartFormData = false;
  let formData;

  // Check if the data contains files or needs to be sent as FormData
  if (data) {
    // Determine if we need FormData based on presence of Files or arrays
    isMultiPartFormData = Object.values(data).some(
      (value) =>
        value instanceof File ||
        (Array.isArray(value) && value.some((item) => item instanceof File))
    );

    if (isMultiPartFormData) {
      formData = new FormData();
      for (let name in data) {
        if (Array.isArray(data[name])) {
          // If the property is an array, append each item with 'name[]'
          data[name].forEach((item) => {
            if (item instanceof File) {
              formData.append(name + "[]", item); // Append file
            } else {
              formData.append(name + "[]", JSON.stringify(item)); // Append other types
            }
          });
        }
        formData.append(name, data[name]);
        // else if (data[name] instanceof File) {
        //   formData.append(name, data[name]); // Append single file
        // } else {
        //   formData.append(name, JSON.stringify(data[name])); // Append other types
        // }
      }
    }
  }

  console.log("Request Data: ", data);
  console.log("Form Data: ", formData);

  const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method,
    headers: {
      ...(!isMultiPartFormData ? { "Content-Type": "application/json" } : {}),
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
    ...(data ? { body: formData ?? JSON.stringify(data) } : {}),
  });

  const resData = await res.json();

  if ([401].includes(resData.status)) {
    logout();
    return;
  }

  if (![200, 201].includes(resData.status)) {
    throw new Error("Request failed", {
      cause: { status: resData.status, message: resData.message },
    });
  }

  return resData;
};

// const call = async (url, method, data) => {
//   let isMultiPartFormData;
//   let formData;
//   if (data?.image instanceof File) {
//     isMultiPartFormData = true;
//     formData = new FormData();
//     for (let name in data) {
//       if (data[name] instanceof File) {
//         formData.append(name, data[name]);
//       } else {
//         formData.append(name, JSON.stringify(data[name]));
//       }
//     }
//   }
//   console.log("line 13 ", data);
//   console.log("line 14 ", formData);
//   const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
//     method: method,
//     headers: {
//       ...(!isMultiPartFormData ? { "content-type": "application/json" } : {}),
//       Authorization: `Bearer ${getTokenFromLocalStorage()}`,
//     },
//     ...(data ? { body: formData ?? JSON.stringify(data) } : {}),
//   });
//   const resData = await res.json();

//   if ([401].includes(resData.status)) {
//     logout();
//     return;
//   }

//   if (![200, 201].includes(resData.status)) {
//     // console.log("line 15 ", resData.status);
//     throw new Error("Request failed", {
//       cause: { status: resData.status, message: resData.message },
//     });
//   }

//   return resData;
// };

export function get(url) {
  return call(url, "GET");
}

export function post(url, data) {
  return call(url, "POST", data);
}

export function patch(url, data) {
  return call(url, "PATCH", data);
}

export function deleteReq(url, data) {
  return call(url, "DELETE", data);
}
