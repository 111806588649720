import { Image, Table, Tag } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TabHeader from "../../components/layouts/TabHeader";
import { DateCell } from "../attendance/attendance/list/AttendanceList";
import { useGetSentNotifications } from "./apis";

const Notifications = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");
  const columns = useColumns();
  const {
    isLoading,
    data,
    mutate: refreshExpenses,
    isValidating,
  } = useGetSentNotifications({ searchText, date });
  return (
    <div className="flex flex-col gap-5">
      <TabHeader
        label="Sent Notifications"
        buttonLabel="Send Notification"
        onReload={refreshExpenses}
        onDateChange={setDate}
        date={date}
        onButtonClick={() => navigate("/notifications/send")}
        onSearch={(text) => setSearchText(text)}
        searchPlaceholder="Search by Name"
      />
      <Table
        columns={columns}
        dataSource={data?.data?.rows ?? []}
        loading={isLoading || isValidating}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default Notifications;

const useColumns = () => {
  return [
    {
      title: () => <div className="flex justify-center">Name</div>,
      dataIndex: "staff",
      key: "staff",
      render: (text) => (
        <div className="flex justify-center">{text?.name ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Message</div>,
      dataIndex: "message",
      key: "message",
      render: (text) => (
        <div className="flex justify-center">{text ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Media</div>,
      dataIndex: "media",
      key: "media",
      render: (text) => (
        <div className="flex justify-center">
          {text ? (
            <Image
              src={`${process.env.REACT_APP_API_URL}/uploads/${text}`}
              height={60}
              width={60}
              className="rounded-full"
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      title: () => <div className="flex justify-center">Date</div>,
      dataIndex: "createdAt",
      key: "createdAt",
      render: DateCell,
    },
    {
      title: () => <div className="flex justify-center">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <div className="flex justify-center">
            {text ? (
              <Tag color="green">Success</Tag>
            ) : (
              <Tag color="red">Failed</Tag>
            )}
          </div>
        );
      },
    },
  ];
};
