import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Select, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { array, object, string } from "yup";
import useSetActiveTab from "../../components/hooks/useSetActiveTab";
import { ElementWithLabel } from "../../components/inputs/Input";
import { TAB_NAMES } from "../../components/layouts/menuNavigation";
import { useNotification } from "../../components/notification/NotificationContext";
import { HorizontalSeparator } from "../members/create/AddMember";
import { useGetAllGymMembers, useSendNotifications } from "./apis";
const { Option } = Select;

const FORM_FIELDS = {
  STAFF: "staffId",
  MESSAGE: "message",
  MEDIA: "image",
};

const SendNotification = () => {
  const navigate = useNavigate();
  useSetActiveTab(TAB_NAMES.NOTIFICATIONS);

  const { errorNotification, successNotification } = useNotification();

  const { isLoading, data } = useGetAllGymMembers();

  const mappedMembersData = useMemo(
    () =>
      data?.data?.rows?.map((item) => {
        return { label: item.name, value: item.id };
      }) ?? [],
    [data?.data?.rows]
  );

  const {
    inProgress,
    execute: sendNotification,
    isSuccess,
    error: addErr,
  } = useSendNotifications();

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.STAFF]: [],
        [FORM_FIELDS.MESSAGE]: null,
        [FORM_FIELDS.MEDIA]: null,
      },
      validationSchema: object({
        [FORM_FIELDS.STAFF]: array()
          .min(1, "At least one item is required")
          .required("This is a Required field."),
        [FORM_FIELDS.MESSAGE]: string().required("This is a Required field."),
      }),
      onSubmit: (data) => {
        console.log("line 61 ", data);
        sendNotification(data);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      successNotification();
      navigate("/notifications");
    }
  }, [isSuccess, navigate, successNotification]);

  useEffect(() => {
    if (addErr) {
      errorNotification();
    }
  }, [addErr, errorNotification]);

  const handleImageChange = (info) => {
    setFieldValue(FORM_FIELDS.MEDIA, info.file.originFileObj);
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    // if (info.file.status === "done") {
    //   // Get this url from response in real world.
    //   getBase64(info.file.originFileObj, (url) => {
    //     setLoading(false);
    //     setFieldValue(FORM_FIELDS.IMAGE, url);
    //   });
    // }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file?.type === "image/jpeg" || file?.type === "image/png";
    if (!isJpgOrPng) {
      errorNotification("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorNotification("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div className="flex flex-col bg-white w-full rounded-xl">
      <div className="text-xl font-bold flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-[#F4F7FE] cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon />
          </div>
          <span>Send Notification</span>
        </div>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={inProgress}
        >
          Send
        </Button>
      </div>
      <HorizontalSeparator />
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 ">
            Attendance Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Here you can add the Attendance of your staff.
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="flex flex-col gap-3">
            <ElementWithLabel
              label="Gym Members (including Staff)"
              error={
                errors[FORM_FIELDS.STAFF] &&
                touched[FORM_FIELDS.STAFF] &&
                errors[FORM_FIELDS.STAFF]
              }
              required={true}
            >
              <Select
                showSearch
                placeholder="Select Staff"
                optionFilterProp="label"
                value={values[FORM_FIELDS.STAFF]}
                onChange={(value) => {
                  if (value.includes("select-all")) {
                    if (values[FORM_FIELDS.STAFF].includes("select-all")) {
                      setFieldValue(FORM_FIELDS.STAFF, []);
                    } else {
                      setFieldValue(FORM_FIELDS.STAFF, ["select-all"]);
                    }
                  } else {
                    setFieldValue(FORM_FIELDS.STAFF, value);
                  }
                }}
                // onSearch={onSearch}
                //options={mappedMembersData}
                mode="multiple"
                allowClear
                loading={isLoading}
              >
                <Option
                  key="select-all"
                  value="select-all"
                  //   onClick={() => {
                  //     setFieldValue(FORM_FIELDS.STAFF, ["select-all"]);
                  //   }}
                >
                  {values[FORM_FIELDS.STAFF].length === mappedMembersData.length
                    ? "Select All"
                    : "Select All"}
                </Option>
                {mappedMembersData.map((option) => {
                  //   console.log("line 186 ", option);
                  return (
                    <Option
                      key={option.value}
                      value={option.value}
                      disabled={values[FORM_FIELDS.STAFF].includes(
                        "select-all"
                      )}
                      //   onClick={() => {
                      //     setFieldValue(FORM_FIELDS.STAFF, [
                      //       ...values[FORM_FIELDS.STAFF],
                      //       option.id,
                      //     ]);
                      //   }}
                    >
                      {option.label}
                    </Option>
                  );
                })}
              </Select>
            </ElementWithLabel>

            <ElementWithLabel
              label={"Message"}
              name={FORM_FIELDS.MESSAGE}
              error={
                errors[FORM_FIELDS.MESSAGE] &&
                touched[FORM_FIELDS.MESSAGE] &&
                errors[FORM_FIELDS.MESSAGE]
              }
              required
            >
              <TextArea
                name={FORM_FIELDS.MESSAGE}
                placeholder="Enter Message"
                value={values[FORM_FIELDS.MESSAGE]}
                onChange={handleChange}
                rows={6}
              />
            </ElementWithLabel>

            <ElementWithLabel
              label={"Image"}
              name={FORM_FIELDS.MEDIA}
              error={
                errors[FORM_FIELDS.MEDIA] &&
                touched[FORM_FIELDS.MEDIA] &&
                errors[FORM_FIELDS.MEDIA]
              }
            >
              <Upload
                name={[FORM_FIELDS.MEDIA]}
                listType="picture-card"
                className="avatar-uploader"
                // listType="picture-circle"
                showUploadList={false}
                //action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                beforeUpload={beforeUpload}
                onChange={handleImageChange}
                customRequest={() => {}}
              >
                {values[FORM_FIELDS.MEDIA] ? (
                  <img
                    src={
                      typeof values[FORM_FIELDS.MEDIA] === "string"
                        ? `${process.env.REACT_APP_API_URL}/uploads/${
                            values[FORM_FIELDS.MEDIA]
                          }`
                        : URL.createObjectURL(values[FORM_FIELDS.MEDIA])
                    }
                    alt="avatar"
                    style={{ width: "100%", height: "100%" }}
                    className="rounded-md object-cover"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ElementWithLabel>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendNotification;
